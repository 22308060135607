import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.any,
};

export default function Logo({ ...other }) {
  return <Box component="img" alt="logo" src="/static/brand/logo_single.svg" height={40} {...other} />;
}
