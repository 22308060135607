import { useContext } from 'react';

import { AppDataContext } from '../contexts/AppDataContext';

// ----------------------------------------------------------------------

const useAppData = () => {
  const context = useContext(AppDataContext);

  if (!context) throw new Error('App Data context must be use inside AppDataProvider');

  return context;
};

export default useAppData;
