import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const yourJWTToken = localStorage.getItem('accessToken');
    config.headers = {
      Authorization: `Bearer ${yourJWTToken}`
    };
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
